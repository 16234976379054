import React from "react"
import styled from "styled-components"
import { H1Blue, medWrapper } from "../../styles/helpers"

import BackgroundPatternOne from "../Graphics/BackgroundPatternOne"
import Newspaper from "../Icons/Newspaper"

const PostGraphic = () => {
  return (
    <PostGraphicStyled>
      <div className="wrapper">
        <div className="intro">
          <div className="intro__title">
            <h2>News and Media</h2>
            <div className="mainIcon">
              <Newspaper />
            </div>
          </div>
        </div>
      </div>
      <div className="backgroundPattern">
        <BackgroundPatternOne />
      </div>
    </PostGraphicStyled>
  )
}

const PostGraphicStyled = styled.section`
  position: relative;

  .wrapper {
    ${medWrapper};
  }

  .intro {
    width: 100%;
    max-width: 90rem;
    margin: 2rem auto;
    text-align: center;

    &__title {
      display: inline-block;
      position: relative;
      margin-bottom: 0;
      padding-top: 2rem;
      padding-left: 10rem;

      h2 {
        ${H1Blue};
      }

      .mainIcon {
        position: absolute;
        top: 3.5rem;
        left: 0;
        width: 8rem;
      }
    }
  }

  .backgroundPattern {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    height: 10rem;
    z-index: -1;

    @media (min-width: 768px) {
      height: 12.5rem;
    }
  }
`

export default PostGraphic
