import React from "react"
import styled from "styled-components"
import { B1Blue } from "../../styles/helpers"

const PostMeta = ({ meta }) => {
  const category = meta.cat.nodes[0].name
  const options = { year: "numeric", month: "long", day: "numeric" }
  const postDate = new Date(meta.date).toLocaleDateString(undefined, options)
  return (
    <PostMetaStyled>
      <p>
        <span>{postDate}</span>
        <span> | </span>
        <span>{category}</span>
      </p>
    </PostMetaStyled>
  )
}

const PostMetaStyled = styled.div`
  width: 100%;

  p {
    ${B1Blue};
    text-transform: uppercase;
  }
`

export default PostMeta
