import React from "react"
import styled from "styled-components"
import { standardWrapper } from "../../styles/helpers"

import PostGraphic from "./PostGraphic"
import PostHeader from "./PostHeader"
import PostFeaturedImage from "./PostFeaturedImage"
import PostWysiwyg from "./PostWysiwyg"
import PostMeta from "./PostMeta"
import PostNav from "./PostNav"

const PostSingle = ({ post, allPosts, prevPost, nextPost }) => {
  return (
    <>
      <PostGraphic />
      <PostSingleArticle>
        <div className="wrapper">
          <PostHeader title={post.title} />
          <PostFeaturedImage image={post.acfPostComponents.featuredImage} />
          <PostWysiwyg content={post.acfPostComponents.mainContent} />
          <PostMeta meta={{ date: post.date, cat: post.categories }} />
        </div>
      </PostSingleArticle>
      <PostNav allPosts={allPosts} prevPost={prevPost} nextPost={nextPost} />
    </>
  )
}

const PostSingleArticle = styled.article`
  .wrapper {
    ${standardWrapper};
    max-width: 95rem !important;
  }
`

export default PostSingle
