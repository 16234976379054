import React from "react"
import styled from "styled-components"
import { H1Blue } from "../../styles/helpers"

const PostHeader = ({ title }) => {
  return (
    <PostHeaderStyled>
      <h1>{title}</h1>
    </PostHeaderStyled>
  )
}

const PostHeaderStyled = styled.header`
  width: 100%;
  text-align: center;

  h1 {
    ${H1Blue};
  }
`

export default PostHeader
